import { axiosInstance } from '../Axios';
import {
	storeData,
	toggleModal,
	toggleModalFetch,
	toggleModalMarkup,
} from './UiReducer';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		loginInfo: sessionStorage.getItem('loginInfo')
			? JSON.parse(sessionStorage.getItem('loginInfo'))
			: null,
		user: null,
		product: null,
		productdelete: null,
		addbanner: null,
		deletebanner: null,
		category: null,
		categorydelete: null,
		addcategory: null,
		createproduct: null,
		deleteproduct: null,
		addFund: null,
		debitFund: null,
		trans: null,
		search: null,
		bannerInfo: null,
		pdInfo: null,
		addpdInfo: null,
		partnermar: null,
		popdescdelete: null,
		sector: null,
		gallery: null,
		booking: null,
		downloadTicket: null,
		agenttrans: null,
		manageMarkup: null,
		manageMarkupUpdate: null,
		updateStatus: null,
		updateVerified: null,
		adminUpdate: null,
		tourdest: null,
		tourpackage: null,
		adminPassword: null,
		footerMenu: null,
		updateFooter: null,
		mail: null,
		userdelete: null,
		category: null,
		categorydelete: null,
		hero: null,
		herodelete: null,
		overview: null,
		overviewdelete: null,
		client: null,
		clientdelete: null,
		feedbackdelete: null,
		feedback: null,
		pc: null,
		pcdelete: null,
		faq: null,
		faqdelete: null,
		hrb: null,
		hrbdelete: null,
	},
	reducers: {
		loginRequest(state, action) {
			state.loading = true;
		},
		loginSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			storeData('loginInfo', action.payload);
			state.error = null;
		},
		loginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userRequest(state, action) {
			state.loading = true;
		},
		userSuccess(state, action) {
			state.loading = false;
			state.user = action.payload;
			state.error = null;
		},
		userFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addBannerRequest(state, action) {
			state.loading = true;
		},
		addBannerSuccess(state, action) {
			state.loading = false;
			state.addbanner = action.payload;
			state.error = null;
		},
		addBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerdeleteRequest(state, action) {
			state.loading = true;
		},
		bannerdeleteSuccess(state, action) {
			state.loading = false;
			state.deletebanner = action.payload;
			state.error = null;
		},
		bannerdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addFundRequest(state, action) {
			state.loading = true;
		},
		addFundSuccess(state, action) {
			state.loading = false;
			state.addFund = action.payload;
			state.error = null;
		},
		addFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitFundRequest(state, action) {
			state.loading = true;
		},
		debitFundSuccess(state, action) {
			state.loading = false;
			state.debitFund = action.payload;
			state.error = null;
		},
		debitFundFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		transRequest(state, action) {
			state.loading = true;
		},
		transSuccess(state, action) {
			state.loading = false;
			state.trans = action.payload;
			state.error = null;
		},
		transFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		searchRequest(state, action) {
			state.loading = true;
		},
		searchSuccess(state, action) {
			state.loading = false;
			state.search = action.payload;
			state.error = null;
		},
		searchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.bannerInfo = action.payload;
			storeData('bannerInfo', action.payload);
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pdRequest(state, action) {
			state.loading = true;
		},
		pdSuccess(state, action) {
			state.loading = false;
			state.pdInfo = action.payload;
			storeData('pdInfo', action.payload);
			state.error = null;
		},
		pdFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		addpdBannerRequest(state, action) {
			state.loading = true;
		},
		addpdBannerSuccess(state, action) {
			state.loading = false;
			state.addpdInfo = action.payload;
			storeData('addpdInfo', action.payload);
			state.error = null;
		},
		addpdBannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		partnermarRequest(state, action) {
			state.loading = true;
		},
		partnermarSuccess(state, action) {
			state.loading = false;
			state.partnermar = action.payload;
			storeData('partnermar', action.payload);
			state.error = null;
		},
		partnermarFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		popdescdeleteRequest(state, action) {
			state.loading = true;
		},
		popdescdeleteSuccess(state, action) {
			state.loading = false;
			state.popdescdelete = action.payload;
			state.error = null;
		},
		popdescdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		bookingRequest(state, action) {
			state.loading = true;
		},
		bookingSuccess(state, action) {
			state.loading = false;
			state.booking = action.payload;
			state.error = null;
		},
		bookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		downloadRequest(state, action) {
			state.loading = true;
		},
		downloadSuccess(state, action) {
			state.loading = false;
			state.downloadTicket = action.payload;
			state.error = null;
		},
		downloadFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agenttransRequest(state, action) {
			state.loading = true;
		},
		agenttransSuccess(state, action) {
			state.loading = false;
			state.agenttrans = action.payload;
			state.error = null;
		},
		agenttransFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		manageMarkupRequest(state, action) {
			state.loading = true;
		},
		manageMarkupSuccess(state, action) {
			state.loading = false;
			state.manageMarkup = action.payload;
			state.error = null;
		},
		manageMarkupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		manageMarkupUpdateRequest(state, action) {
			state.loading = true;
		},
		manageMarkupUpdateSuccess(state, action) {
			state.loading = false;
			state.manageMarkupUpdate = action.payload;
			state.error = null;
		},
		manageMarkupUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		statusUpdateRequest(state, action) {
			state.loading = true;
		},
		statusUpdateSuccess(state, action) {
			state.loading = false;
			state.updateStatus = action.payload;
			state.error = null;
		},
		statusUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		verifiedUpdateRequest(state, action) {
			state.loading = true;
		},
		verifiedUpdateSuccess(state, action) {
			state.loading = false;
			state.updateVerified = action.payload;
			state.error = null;
		},
		verifiedUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminUpdateRequest(state, action) {
			state.loading = true;
		},
		adminUpdateSuccess(state, action) {
			state.loading = false;
			state.loginInfo = action.payload;
			state.error = null;
		},
		adminUpdateFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourdestRequest(state, action) {
			state.loading = true;
		},
		tourdestSuccess(state, action) {
			state.loading = false;
			state.tourdest = action.payload;
			state.error = null;
		},
		tourdestFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourpackageRequest(state, action) {
			state.loading = true;
		},
		tourpackageSuccess(state, action) {
			state.loading = false;
			state.tourpackage = action.payload;
			state.error = null;
		},
		tourpackageFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		adminPasswordRequest(state, action) {
			state.loading = true;
		},
		adminPasswordSuccess(state, action) {
			state.loading = false;
			state.adminPassword = action.payload;
			state.error = null;
		},
		adminPasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		footerMenuRequest(state, action) {
			state.loading = true;
		},
		footerMenuSuccess(state, action) {
			state.loading = false;
			state.footerMenu = action.payload;
			state.error = null;
		},
		footerMenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		updateFooterRequest(state, action) {
			state.loading = true;
		},
		updateFooterSuccess(state, action) {
			state.loading = false;
			state.updateFooter = action.payload;
			state.error = null;
		},
		updateFooterFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mailRequest(state, action) {
			state.loading = true;
		},
		mailSuccess(state, action) {
			state.loading = false;
			state.mail = action.payload;
			state.error = null;
		},
		mailFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		userdeleteRequest(state, action) {
			state.loading = true;
		},
		userdeleteSuccess(state, action) {
			state.loading = false;
			state.userdelete = action.payload;
			state.error = null;
		},
		userdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productRequest(state, action) {
			state.loading = true;
		},
		productSuccess(state, action) {
			state.loading = false;
			state.product = action.payload;
			state.error = null;
		},
		productFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		productdeleteRequest(state, action) {
			state.loading = true;
		},
		productdeleteSuccess(state, action) {
			state.loading = false;
			state.productdelete = action.payload;
			state.error = null;
		},
		productdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categoryRequest(state, action) {
			state.loading = true;
		},
		categorySuccess(state, action) {
			state.loading = false;
			state.category = action.payload;
			state.error = null;
		},
		categoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		categorydeleteRequest(state, action) {
			state.loading = true;
		},
		categorydeleteSuccess(state, action) {
			state.loading = false;
			state.categorydelete = action.payload;
			state.error = null;
		},
		categorydeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		heroRequest(state, action) {
			state.loading = true;
		},
		heroSuccess(state, action) {
			state.loading = false;
			state.hero = action.payload;
			state.error = null;
		},
		heroFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		herodeleteRequest(state, action) {
			state.loading = true;
		},
		herodeleteSuccess(state, action) {
			state.loading = false;
			state.herodelete = action.payload;
			state.error = null;
		},
		herodeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		overviewRequest(state, action) {
			state.loading = true;
		},
		overviewSuccess(state, action) {
			state.loading = false;
			state.overview = action.payload;
			state.error = null;
		},
		overviewFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		overviewdeleteRequest(state, action) {
			state.loading = true;
		},
		overviewdeleteSuccess(state, action) {
			state.loading = false;
			state.overviewdelete = action.payload;
			state.error = null;
		},
		overviewdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		clientRequest(state, action) {
			state.loading = true;
		},
		clientSuccess(state, action) {
			state.loading = false;
			state.client = action.payload;
			state.error = null;
		},
		clientFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		clientdeleteRequest(state, action) {
			state.loading = true;
		},
		clientdeleteSuccess(state, action) {
			state.loading = false;
			state.clientdelete = action.payload;
			state.error = null;
		},
		clientdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		feedbackRequest(state, action) {
			state.loading = true;
		},
		feedbackSuccess(state, action) {
			state.loading = false;
			state.feedback = action.payload;
			state.error = null;
		},
		feedbackFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		feedbackdeleteRequest(state, action) {
			state.loading = true;
		},
		feedbackdeleteSuccess(state, action) {
			state.loading = false;
			state.feedbackdelete = action.payload;
			state.error = null;
		},
		feedbackdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pcRequest(state, action) {
			state.loading = true;
		},
		pcSuccess(state, action) {
			state.loading = false;
			state.pc = action.payload;
			state.error = null;
		},
		pcFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		pcdeleteRequest(state, action) {
			state.loading = true;
		},
		pcdeleteSuccess(state, action) {
			state.loading = false;
			state.pcdelete = action.payload;
			state.error = null;
		},
		pcdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		faqRequest(state, action) {
			state.loading = true;
		},
		faqSuccess(state, action) {
			state.loading = false;
			state.faq = action.payload;
			state.error = null;
		},
		faqFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		faqdeleteRequest(state, action) {
			state.loading = true;
		},
		faqdeleteSuccess(state, action) {
			state.loading = false;
			state.faqdelete = action.payload;
			state.error = null;
		},
		faqdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		hrbRequest(state, action) {
			state.loading = true;
		},
		hrbSuccess(state, action) {
			state.loading = false;
			state.hrb = action.payload;
			state.error = null;
		},
		hrbFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		hrbdeleteRequest(state, action) {
			state.loading = true;
		},
		hrbdeleteSuccess(state, action) {
			state.loading = false;
			state.hrbdelete = action.payload;
			state.error = null;
		},
		hrbdeleteFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		api_key: 'nOI99ik9GLBPhi31sJU7TVlMso5vOF06',
	},
};
const config2 = {
	headers: {
		'Content-Type': 'multipart/form-data',
		Accept: 'application/json',
	},
};
const config3 = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjO7hqe3PG9musafi==',
	},
};
export const {
	loginRequest,
	loginSuccess,
	loginFail,
	userRequest,
	userSuccess,
	userFail,
	addBannerRequest,
	addBannerSuccess,
	addBannerFail,
	bannerdeleteRequest,
	bannerdeleteSuccess,
	bannerdeleteFail,
	addFundRequest,
	addFundSuccess,
	addFundFail,
	debitFundRequest,
	debitFundSuccess,
	debitFundFail,
	transRequest,
	transSuccess,
	transFail,
	searchRequest,
	searchSuccess,
	searchFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	pdRequest,
	pdSuccess,
	pdFail,
	addpdBannerRequest,
	addpdBannerSuccess,
	addpdBannerFail,
	partnermarRequest,
	partnermarSuccess,
	partnermarFail,
	popdescdeleteRequest,
	popdescdeleteSuccess,
	popdescdeleteFail,
	bookingRequest,
	bookingSuccess,
	bookingFail,
	downloadRequest,
	downloadSuccess,
	downloadFail,
	agenttransRequest,
	agenttransSuccess,
	agenttransFail,
	manageMarkupRequest,
	manageMarkupSuccess,
	manageMarkupFail,
	manageMarkupUpdateRequest,
	manageMarkupUpdateSuccess,
	manageMarkupUpdateFail,
	statusUpdateRequest,
	statusUpdateSuccess,
	statusUpdateFail,
	verifiedUpdateRequest,
	verifiedUpdateSuccess,
	verifiedUpdateFail,
	adminUpdateRequest,
	adminUpdateSuccess,
	adminUpdateFail,
	tourdestRequest,
	tourdestSuccess,
	tourdestFail,
	tourpackageRequest,
	tourpackageSuccess,
	tourpackageFail,
	adminPasswordRequest,
	adminPasswordSuccess,
	adminPasswordFail,
	footerMenuRequest,
	footerMenuSuccess,
	footerMenuFail,
	updateFooterRequest,
	updateFooterSuccess,
	updateFooterFail,
	mailRequest,
	mailSuccess,
	mailFail,
	userdeleteRequest,
	userdeleteSuccess,
	userdeleteFail,
	productRequest,
	productSuccess,
	productFail,
	productdeleteRequest,
	productdeleteSuccess,
	productdeleteFail,
	categoryRequest,
	categorySuccess,
	categoryFail,
	categorydeleteRequest,
	categorydeleteSuccess,
	categorydeleteFail,
	heroRequest,
	heroSuccess,
	heroFail,
	herodeleteRequest,
	herodeleteSuccess,
	herodeleteFail,
	overviewRequest,
	overviewSuccess,
	overviewFail,
	overviewdeleteRequest,
	overviewdeleteSuccess,
	overviewdeleteFail,
	clientRequest,
	clientSuccess,
	clientFail,
	clientdeleteRequest,
	clientdeleteSuccess,
	clientdeleteFail,
	feedbackRequest,
	feedbackSuccess,
	feedbackFail,
	feedbackdeleteRequest,
	feedbackdeleteSuccess,
	feedbackdeleteFail,
	pcRequest,
	pcSuccess,
	pcFail,
	pcdeleteRequest,
	pcdeleteSuccess,
	pcdeleteFail,
	faqRequest,
	faqSuccess,
	faqFail,
	faqdeleteRequest,
	faqdeleteSuccess,
	faqdeleteFail,
	hrbRequest,
	hrbSuccess,
	hrbFail,
	hrbdeleteRequest,
	hrbdeleteSuccess,
	hrbdeleteFail,
} = actions;

export const loginDispatch =
	(bodyData, setLoading, navigate) => async (dispatch) => {
		try {
			dispatch(loginRequest());
			const { data } = await axiosInstance.post(
				'/loginpartner',
				bodyData,
				config
			);
			dispatch(loginSuccess(data));
			if (data.token) {
				navigate('/dashboard/default');
				setLoading(false);
			} else {
				setLoading(false);
				alert('Something went wrong');
			}
		} catch (error) {
			setLoading(false);
			alert('Something went wrong');
			dispatch(
				loginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const RegisterUserDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			dispatch(userRequest());
			const { data } = await axiosInstance.post(
				'/registerCustomer',
				bodyData,
				config
			);
			console.log('data', data);
			dispatch(userSuccess(data));
			if (data) {
				alert(data.message);
				navigate('/dashboard/userlist');
			}
		} catch (error) {
			alert(error);
			dispatch(
				userFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const AdminUpdateDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(adminUpdateRequest());
		const { data } = await axiosInstance.post('/updateUser', bodyData, config);
		console.log('data', data);
		dispatch(adminUpdateSuccess(data));
		if (data) {
			alert(data.message);
		}
	} catch (error) {
		alert(error);
		dispatch(
			adminUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AdminChangePasswordDispatch =
	(bodyData, navigate, token) => async (dispatch) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			dispatch(adminPasswordRequest());
			const { data } = await axiosInstance.post(
				'/changepassword',
				bodyData,
				config
			);
			// console.log('data', data);
			dispatch(adminPasswordSuccess(data));
			if (data) {
				alert(data.message);
				navigate('/dashboard/default');
			} else {
				alert(data.result?.message);
			}
		} catch (error) {
			dispatch(
				adminPasswordFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const showUserDispatch = (bodyData, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(userRequest());
		const { data } = await axiosInstance.post('/customer', bodyData, config);
		console.log('data', data);
		dispatch(userSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			userFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteUserDispatch = (token, id, form) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(userdeleteRequest());
		const { data } = await axiosInstance.delete(`/customer/${id}`, config);
		console.log('data', data);
		dispatch(userdeleteSuccess(data));
		if (data) {
			dispatch(showUserDispatch(form, token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			userdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showproductDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(productRequest());
		const { data } = await axiosInstance.get('/product', config);
		console.log('data', data);
		dispatch(productSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			productFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteProductDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(productdeleteRequest());
		const { data } = await axiosInstance.delete(`/product/${id}`, config);
		console.log('data', data);
		dispatch(productdeleteSuccess(data));
		if (data) {
			dispatch(showproductDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			productdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showCategoryDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(categoryRequest());
		const { data } = await axiosInstance.get('/category', config);
		console.log('data', data);
		dispatch(categorySuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			categoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteCategoryDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(categorydeleteRequest());
		const { data } = await axiosInstance.delete(`/category/${id}`, config);
		console.log('data', data);
		dispatch(categorydeleteSuccess(data));
		if (data) {
			dispatch(showCategoryDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			categorydeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showHeroDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(heroRequest());
		const { data } = await axiosInstance.get('/hero', config);
		console.log('data', data);
		dispatch(heroSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			heroFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteHeroDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(herodeleteRequest());
		const { data } = await axiosInstance.delete(`/hero/${id}`, config);
		console.log('data', data);
		dispatch(herodeleteSuccess(data));
		if (data) {
			dispatch(showHeroDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			herodeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showClientDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(clientRequest());
		const { data } = await axiosInstance.get('/client', config);
		console.log('data', data);
		dispatch(clientSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			clientFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteClientDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(clientdeleteRequest());
		const { data } = await axiosInstance.delete(`/client/${id}`, config);
		console.log('data', data);
		dispatch(clientdeleteSuccess(data));
		if (data) {
			dispatch(showClientDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			clientdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showFeedbackDispatch = (form, token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
				api_key: 'nOI99ik9GLBPhi31sJU7TVlMso5vOF06',
			},
		};

		dispatch(feedbackRequest());
		const { data } = await axiosInstance.post('/partnerfeedback', form, config);
		console.log('data', data);
		dispatch(feedbackSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			feedbackFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltefeedbackDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(feedbackdeleteRequest());
		const { data } = await axiosInstance.delete(`/feedback/${id}`, config);
		console.log('data', data);
		dispatch(feedbackdeleteSuccess(data));
		if (data) {
			dispatch(showFeedbackDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			feedbackdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showOverviewDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(overviewRequest());
		const { data } = await axiosInstance.get('/overview', config);
		console.log('data', data);
		dispatch(overviewSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			overviewFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const delteOverviewDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(overviewdeleteRequest());
		const { data } = await axiosInstance.delete(`/overview/${id}`, config);
		console.log('data', data);
		dispatch(overviewdeleteSuccess(data));
		if (data) {
			dispatch(showOverviewDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			overviewdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showpcDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(pcRequest());
		const { data } = await axiosInstance.get('/pc', config);
		console.log('data', data);
		dispatch(pcSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			pcFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltepcDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(pcdeleteRequest());
		const { data } = await axiosInstance.delete(`/pc/${id}`, config);
		console.log('data', data);
		dispatch(pcdeleteSuccess(data));
		if (data) {
			dispatch(showpcDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			pcdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showhrbDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(hrbRequest());
		const { data } = await axiosInstance.get('/hrb', config);
		console.log('data', data);
		dispatch(hrbSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			hrbFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltehrbDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(hrbdeleteRequest());
		const { data } = await axiosInstance.delete(`/hrb/${id}`, config);
		console.log('data', data);
		dispatch(hrbdeleteSuccess(data));
		if (data) {
			dispatch(showhrbDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			hrbdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showFaqDispatch = (token) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(faqRequest());
		const { data } = await axiosInstance.get('/faq', config);
		console.log('data', data);
		dispatch(faqSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			faqFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deltefaqDispatch = (token, id) => async (dispatch) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		};
		dispatch(faqdeleteRequest());
		const { data } = await axiosInstance.delete(`/faq/${id}`, config);
		console.log('data', data);
		dispatch(faqdeleteSuccess(data));
		if (data) {
			dispatch(showFaqDispatch(token));
		}
	} catch (error) {
		console.log('error', error);
		dispatch(
			faqdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showBookingDispatch = () => async (dispatch) => {
	try {
		dispatch(bookingRequest());
		const { data } = await axiosInstance.get('/home/adminBookingList', config3);
		console.log('data', data);
		dispatch(bookingSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			bookingFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showpartnermarDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(partnermarRequest());
		const { data } = await axiosInstance.post(
			'/partner/showpartnermarkup',
			bodyData,
			config
		);
		dispatch(partnermarSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			partnermarFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtransDispatch = () => async (dispatch) => {
	try {
		dispatch(transRequest());
		const { data } = await axiosInstance.get('/home/admintran', config3);
		console.log('data', data);
		dispatch(transSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			transFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showagenttransDispatch = () => async (dispatch) => {
	try {
		dispatch(agenttransRequest());
		const { data } = await axiosInstance.get('/home/agenttran', config3);
		console.log('data', data);
		dispatch(agenttransSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			agenttransFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showsearchDispatch = () => async (dispatch) => {
	try {
		dispatch(searchRequest());
		const { data } = await axiosInstance.get(
			'/home/adminflightsearchAll',
			config3
		);
		console.log('data', data);
		dispatch(searchSuccess(data));
	} catch (error) {
		console.log('error', error);
		dispatch(
			searchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addFundAgentDispatch = (body) => async (dispatch) => {
	try {
		dispatch(toggleModal(false));
		dispatch(addFundRequest());
		const { data } = await axiosInstance.post('/home/addFund', body, config3);
		console.log('data', data);
		dispatch(addFundSuccess(data));
		if (data.status === 200) {
			alert('Transaction Successfully');
		}
		// dispatch(showagentDispatch());
	} catch (error) {
		console.log('error', error);
		dispatch(
			addFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const debitFundAgentDispatch = (body) => async (dispatch) => {
	try {
		dispatch(toggleModalFetch(false));
		dispatch(debitFundRequest());
		const { data } = await axiosInstance.post('/home/fetchFund', body, config3);
		console.log('data', data);
		dispatch(debitFundSuccess(data));
		if (data.status === 200) {
			alert('Transaction Successfully');
		} else {
			alert('Transaction Failed');
		}
		// dispatch(showagentDispatch());
	} catch (error) {
		console.log('error', error);
		dispatch(
			debitFundFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const createbannerDispatch =
	(bodyData, navigate) => async (dispatch) => {
		try {
			// onUploadProgress: function (progressEvent) {
			// 	var percentCompleted = Math.round(
			// 		(progressEvent.loaded * 100) / progressEvent.total
			// 	);
			// 	console.log(percentCompleted);
			// },

			dispatch(addBannerRequest());
			const { data } = await axios.post(
				'https://s.marinersforex.com/api/home/addbanner',
				bodyData,
				config2
				// prog
			);
			console.log('data', data);

			dispatch(addBannerSuccess(data));
			navigate('/dashboard/bannerlist');
		} catch (error) {
			dispatch(
				addBannerFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const showBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/banner',
			config
		);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const pdBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(pdRequest());
		const { data } = await axiosInstance.get('/product', config);
		console.log('data', data);
		dispatch(pdSuccess(data));
	} catch (error) {
		dispatch(
			pdFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addbannerDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(addpdBannerRequest());
		const { data } = await axiosInstance.post('/product', bodyData, config2);
		console.log('data', data);
		dispatch(addpdBannerSuccess(data));
		navigate('/dashboard/pslist');
	} catch (error) {
		dispatch(
			addpdBannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletebannerDispatch = (id) => async (dispatch) => {
	try {
		dispatch(bannerdeleteRequest());
		const { data } = await axios.delete(
			`https://s.marinersforex.com/api/home/bannerdelete/${id}`,
			config
		);
		console.log('data', data);
		if (data) {
			dispatch(showBannerDispatch());
		}
		dispatch(bannerdeleteSuccess(data));
	} catch (error) {
		dispatch(
			bannerdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const deletepopdescDispatch = (id) => async (dispatch) => {
	try {
		dispatch(popdescdeleteRequest());
		const { data } = await axiosInstance.delete(`/product/${id}`, config);
		console.log('data', data);
		if (data) {
			dispatch(pdBannerDispatch());
		}
		dispatch(popdescdeleteSuccess(data));
	} catch (error) {
		dispatch(
			popdescdeleteFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const updatebannerDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(loginRequest());
		console.log('body', bodyData);

		const { data } = await axiosInstance.post('login', bodyData, config);
		console.log('data', data);
		dispatch(loginSuccess(data));
		// setLoading(false);
	} catch (error) {
		dispatch(
			loginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const downloadFlightTicket = (orderId) => async (dispatch) => {
	try {
		dispatch(downloadRequest());
		const { data } = await axios.get(
			`https://api.musafirbazar.com/api/user/downloadticket/${orderId}`,
			config
		);
		dispatch(downloadSuccess(data));
	} catch (error) {
		dispatch(
			downloadFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showManageMarkupDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(manageMarkupRequest());
		const { data } = await axiosInstance.post(
			'/home/managemarkupget',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(manageMarkupSuccess(data));
	} catch (error) {
		dispatch(
			manageMarkupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const ManageMarkupUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(toggleModalMarkup(false));
		dispatch(manageMarkupUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/managemarkup',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(manageMarkupUpdateSuccess(data));
		if (data.status === 200) {
			alert('Update Successfully');
		}
	} catch (error) {
		dispatch(
			manageMarkupUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AgentStatusUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(statusUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/updateAgentStatus',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(statusUpdateSuccess(data));
		if (data.status === 200) {
			// dispatch(showagentDispatch());
		}
	} catch (error) {
		dispatch(
			statusUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const AgentVerifiedUpdateDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(verifiedUpdateRequest());
		const { data } = await axiosInstance.post(
			'/home/updateAgentVerified',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(verifiedUpdateSuccess(data));
		if (data.status === 200) {
			// dispatch(showagentDispatch());
		}
	} catch (error) {
		dispatch(
			verifiedUpdateFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showtourdestDispatch = () => async (dispatch) => {
	try {
		dispatch(tourdestRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/tourdest',
			config
		);
		console.log('data', data);
		dispatch(tourdestSuccess(data));
	} catch (error) {
		dispatch(
			tourdestFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourpackageDispatch = () => async (dispatch) => {
	try {
		dispatch(tourpackageRequest());
		const { data } = await axios.get(
			'https://s.marinersforex.com/api/home/tourpackage',
			config
		);
		console.log('data', data);
		dispatch(tourpackageSuccess(data));
	} catch (error) {
		dispatch(
			tourpackageFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showFootermenuDispatch = () => async (dispatch) => {
	try {
		dispatch(footerMenuRequest());
		const { data } = await axiosInstance.get('/home/footer_submenu', config3);
		console.log('data', data);
		dispatch(footerMenuSuccess(data));
	} catch (error) {
		dispatch(
			footerMenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const UpdateFooterMenuDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(updateFooterRequest());
		const { data } = await axiosInstance.post(
			'/home/updateFooterMenu',
			bodyData,
			config3
		);
		console.log('data', data);
		dispatch(updateFooterSuccess(data));
	} catch (error) {
		dispatch(
			updateFooterFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const mailFlightTicket = (bodyData) => async (dispatch) => {
	try {
		dispatch(mailRequest());
		const { data } = await axios.post(
			`https://s.marinersforex.com/api/home/mailticket`,
			bodyData,
			config
		);
		dispatch(mailSuccess(data));
		if (data.status === 200) {
			alert('Ticket Sent Successfully');
		}
	} catch (error) {
		dispatch(
			mailFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
