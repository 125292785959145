import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./HRBList')));
const AddCategory = Loadable(lazy(() => import('./AddHRB')));

const HRBRoutes = [
	{
		path: '/dashboard/hrblist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addhrb',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default HRBRoutes;
