import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./FeedbackList')));
const AddCategory = Loadable(lazy(() => import('./AddFeedback')));

const FeedbackRoutes = [
	{
		path: '/dashboard/feedbacklist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addfeedback',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default FeedbackRoutes;
