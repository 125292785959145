import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./ClientList')));
const AddCategory = Loadable(lazy(() => import('./AddClient')));

const ClientRoutes = [
	{
		path: '/dashboard/clientlist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addclient',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default ClientRoutes;
