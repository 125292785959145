import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { delteProductDispatch } from 'reducers/HomeReducer';
import { Box, Button, Switch } from '@mui/material';

export default function ProductTable() {
	const { product, loginInfo } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	const columns = [
		{ field: 'id', headerName: 'ID', width: 90 },
		{
			field: 'productId',
			headerName: 'Product Id',
			width: 150,
			editable: true,
		},
		{
			field: 'title',
			headerName: 'Title',
			width: 150,
			editable: true,
		},
		{
			field: 'subtitle',
			headerName: 'SubTitle',
			// type: 'number',
			width: 150,
			editable: true,
		},
		{
			field: 'price',
			headerName: 'Price',
			width: 150,
			editable: true,
		},
		{
			field: 'discount_price',
			headerName: 'Discount Price',
			width: 200,
			editable: true,
		},
		{
			field: 'star',
			headerName: 'Star',
			width: 200,
			editable: true,
		},
		{
			field: 'content',
			headerName: 'Content',
			width: 200,
			editable: true,
		},
		{
			field: 'videourl',
			headerName: 'Video Url',
			width: 200,
			editable: true,
		},
		{
			field: 'imgpath',
			headerName: 'Image',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<>
						<img
							src={cellValues.row.imgpath}
							style={{ width: 100, height: 100, resize: 'contain' }}
						/>
					</>
				);
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Switch
							checked={cellValues.value === 'active' ? true : false}
							onChange={() => {
								let form = {
									agentId: cellValues.id,
									status: cellValues.value === '1' ? '0' : '1',
								};
								// dispatch(AgentStatusUpdateDispatch(form));
							}}
							color='primary'
							name='checkedB'
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
					</Box>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 300,
			editable: true,
			renderCell: (cellValues) => {
				return (
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						{/* <Button
							variant='contained'
							color='primary'
							onClick={(event) => {
								handleClick(event, cellValues);
								// console.log('id', cellValues.id);
							}}>
							Edit
						</Button> */}
						<Button
							variant='contained'
							style={{ marginLeft: 10, lineHeight: '1rem', background: 'red' }}
							onClick={(event) => {
								dispatch(delteProductDispatch(loginInfo?.token, cellValues.id));
							}}>
							Delete
						</Button>
					</Box>
				);
			},
		},
	];
	const handleClick = (event, cellValues) => {};
	const handleClickDelete = (event, cellValues) => {
		// dispatch(deleteproductDispatch(cellValues.id, loginInfo.token));
	};
	return (
		<Box sx={{ height: 400, width: '100%' }}>
			{product?.result.length > 0 ? (
				<DataGrid
					rows={product?.result}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 5,
							},
						},
					}}
					pageSizeOptions={[5]}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			) : (
				''
			)}
		</Box>
	);
}
