import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./ProductCategoryList')));
const AddCategory = Loadable(lazy(() => import('./AddProductCategory')));

const ProductCategoryRoutes = [
	{
		path: '/dashboard/productcategorylist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addproductcategory',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default ProductCategoryRoutes;
