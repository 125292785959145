import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./OverviewList')));
const AddCategory = Loadable(lazy(() => import('./AddOverview')));

const OverviewRoutes = [
	{
		path: '/dashboard/overviewlist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addoverview',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default OverviewRoutes;
