import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./HeroList')));
const AddCategory = Loadable(lazy(() => import('./AddHero')));

const HeroRoutes = [
	{
		path: '/dashboard/herolist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addhero',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
];

export default HeroRoutes;
